<template>
  <b-field :label="placeholder">
    <Treeselect
      v-model="selected_items"
      v-bind:class="size"
      :disabled="disabled"
      :placeholder="$t('choose')"
      open-on-focus
      open-on-click
      :multiple="true"
      :options="items"
      :normalizer="normalizer"
    >
      <div
        slot="value-label"
        slot-scope="{ node }"
      >
        {{ node.raw.label }}
      </div>
    </treeselect>
  </b-field>
</template>

<script>
// import the component
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: { Treeselect },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    openAll: {
      type: Boolean,
      default: false
    },
    selected: {
      type: [Number, Array, String],
      default: null
    },
    size: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      selected_items: [],
      emit: true
    };
  },
  watch: {
    value(newVal) {
      //  if (newVal && newVal.length != 0) {
      //    this.emit = false;
      //  }
      this.selected_items = newVal;
    },
    selected_items(newVal) {
      if (this.emit) {
        this.$emit("change", newVal);
      } else {
        this.emit = true;
      }
    }
  },
  created() {
    if (this.selected) {
      if (Array.isArray(this.selected)) {
        this.selected_items = [...this.selected_items, ...this.selected];
      } else {
        this.selected_items = [this.selected];
      }
    }
  },
  methods: {
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
        children: node.children
      };
    }
  }
};
</script>
