<template>
  <div
    v-on-clickaway="away"
    class="dates-dropdown"
  >
    <span
      v-bind:class="{
        'has-text-primary': showMenu,
        'has-text-grey-light': disabled,
        'is-clickable': !disabled
      }"
      @click="setShowMenu()"
    >
      <b-icon
        icon="calendar"
        size="is-small"
        class="mr-1"
      />
      <span v-if="dateRange">
        {{ $t(dateRange) }}
      </span>
      <span v-else>{{ displayDate }}
        <span
          v-if="displayDate2"
          class="is-block is-size-7 has-text-grey"
        >{{ $t("compare_to") }} {{ displayDate2 }}</span>
      </span>
    </span>
    <div
      class="menu-ol"
      :class="{ active: showMenu }"
    >
      <div class="columns is-mobile is-multiline">
        <div class="column is-5-tablet is-12-mobile">
          <b-datepicker
            v-if="showPicker1"
            :key="'datepicker1-' + datepickerKey"
            ref="datepicker"
            v-model="picker_date"
            size="is-small"
            inline
            range
            :min-date="new Date('01/01/2013')"
            :max-date="maxDate ? maxDate : maximumDate"
            @range-start="dateFromSelected = true"
            @range-end="dateFromSelected = false"
          />
          <b-datepicker
            v-else-if="hasCompare"
            :key="'datepicker2-' + datepickerKey"
            ref="datepicker"
            v-model="picker_date2"
            class="compare"
            size="is-small"
            inline
            range
            :min-date="new Date('01/01/2013')"
            :max-date="maxDate ? maxDate : maximumDate"
            @range-start="dateFromSelected = true"
            @range-end="dateFromSelected = false"
          />
        </div>

        <div class="column is-7-tablet is-12-mobile">
          <div class="columns is-multiline is-mobile mt-1 is-variable is-1">
            <div
              v-for="opt in dateRageTypes"
              :key="opt.id"
              class="column is-4"
            >
              <b-button
                :type="dateRange == opt.id ? 'is-primary is-light' : 'is-light'"
                expanded
                size="is-small"
                @click="setDateRange(opt.id)"
              >
                {{ opt.text }}
              </b-button>
            </div>

            <div class="column is-6 mt-2">
              <b-input-validation
                ref="picker1_from"
                :key="showPicker1 && dateFromSelected"
                v-model="date_filter[0]"
                input-ref="picker1_from"
                input-type="date"
                size="is-small"
                :type="showPicker1 && !dateFromSelected ? 'is-info' : ''"
                @blur="dateFilter"
                @focus="date1Focused(false)"
              />
            </div>
            <div class="column is-6 mt-2">
              <b-input-validation
                ref="picker1_to"
                :key="showPicker1 && dateFromSelected"
                v-model="date_filter[1]"
                input-type="date"
                input-ref="picker1_to"
                size="is-small"
                :type="showPicker1 && dateFromSelected ? 'is-info' : ''"
                @blur="dateFilter"
                @focus="date1Focused(true)"
              />
            </div>

            <template v-if="hasCompare">
              <div class="column is-4 is-flex is-align-items-center mt-4">
                <div class="field">
                  <b-checkbox v-model="compareDates">
                    {{
                      $t("compare_to")
                    }}
                  </b-checkbox>
                </div>
              </div>

              <div
                v-for="opt in dateRageCompareTypes"
                :key="'compare' + opt.id"
                class="column is-4 mt-4"
              >
                <b-button
                  :disabled="!compareDates"
                  size="is-small"
                  expanded
                  :type="
                    dateRange2 == opt.id && compareDates
                      ? 'is-primary is-light'
                      : 'is-light'
                  "
                  @click="setDateRange2(opt.id)"
                >
                  {{ opt.text }}
                </b-button>
              </div>

              <template v-if="compareDates">
                <div class="column is-6 mt-2">
                  <b-input-validation
                    :key="showPicker1 && dateFromSelected"
                    v-model="date_filter_compare[0]"
                    input-type="date"
                    size="is-small"
                    :type="!showPicker1 && !dateFromSelected ? 'is-info' : ''"
                    @blur="dateFilter2"
                    @focus="date2Focused(false)"
                  />
                </div>

                <div class="column is-6 mt-2">
                  <b-input-validation
                    :key="showPicker1 && dateFromSelected"
                    v-model="date_filter_compare[1]"
                    input-type="date"
                    size="is-small"
                    :type="!showPicker1 && dateFromSelected ? 'is-info' : ''"
                    @blur="dateFilter2"
                    @focus="date2Focused(true)"
                  />
                </div>
              </template>
            </template>
          </div>
          <b-button
            size="is-small"
            type="is-primary"
            @click="apply"
          >
            {{
              $t("apply")
            }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mixin as clickaway } from "vue-clickaway2";

export default {
  mixins: [clickaway],
  props: {
    hasCompare: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxDate: {
      type: Date,
      default: null
    }
  },
  data() {
    return {
      dateFromSelected: false,
      datepickerKey: 1,
      showMenu: false,
      displayDate: "",
      displayDate2: null,
      showPicker1: true,
      compareDates: false,
      pickerWatcher: true,
      picker2Watcher: true,
      dateRange: "last_seven_days",
      dateRange2: null,
      maximumDate: new Date(),
      dateRageTypes: [
        {
          id: "today",
          text: this.$t("today")
        },
        {
          id: "yesterday",
          text: this.$t("yesterday")
        },
        {
          id: "last_seven_days",
          text: this.$t("last_seven_days")
        },
        {
          id: "last_thirty_days",
          text: this.$t("last_thirty_days")
        },
        {
          id: "current_month",
          text: this.$t("current_month")
        },
        {
          id: "last_month",
          text: this.$t("last_month")
        }
      ],
      dateRageCompareTypes: [
        {
          id: "last_month",
          text: this.$t("last_month")
        },
        {
          id: "last_year",
          text: this.$t("last_year")
        }
      ],
      picker_date: null,
      picker_date2: null,
      date_filter: null,
      date_filter_compare: null
    };
  },
  watch: {
    compareDates(newVal) {
      if (newVal) {
        this.setDateRange2("last_month");
        this.showPicker1 = false;
        this.dateFromSelected = false;
      }
    },
    picker_date(newVal) {
      if (this.pickerWatcher) {
        this.changeDate(newVal);
      } else {
        this.pickerWatcher = true;
      }
    },
    picker_date2(newVal) {
      if (this.picker2Watcher) {
        this.changeDate2(newVal);
      } else {
        this.picker2Watcher = true;
      }
    }
  },
  created() {
    let obj = this.setDates(this.dateRange);
    this.date_filter = [obj.from, obj.to];
    this.pickerWatcher = false;
    this.picker_date = [new Date(obj.from), new Date(obj.to)];
    this.apply();
  },
  methods: {
    setDateRange(newVal) {
      this.dateRange = newVal;
      let obj = this.setDates(newVal);
      if (obj && obj.from && obj.to) {
        this.date_filter = [obj.from, obj.to];
        this.pickerWatcher = false;
        this.picker_date = [new Date(obj.from), new Date(obj.to)];
      }
      this.apply();
    },
    setDateRange2(newVal) {
      this.dateRange2 = newVal;
      let obj = this.setDatesCompare(newVal);
      if (obj && obj.from && obj.to) {
        this.date_filter_compare = [obj.from, obj.to];
        this.picker2Watcher = false;
        this.picker_date2 = [new Date(obj.from), new Date(obj.to)];
      }
      if (newVal) {
        this.apply();
      }
    },
    setShowMenu() {
      if (!this.disabled) this.showMenu = true;
    },
    away() {
      this.showMenu = false;
    },
    apply() {
      this.displayDate = `${this.date_filter[0]} - ${this.date_filter[1]}`;
      let obj = {
        date: {
          from: this.date_filter[0],
          to: this.date_filter[1]
        }
      };
      if (this.compareDates) {
        obj.compare_date = {
          from:
            (this.date_filter_compare && this.date_filter_compare[0]) || null,
          to: (this.date_filter_compare && this.date_filter_compare[1]) || null
        };
        this.displayDate2 = `${this.date_filter_compare[0]} - ${this.date_filter_compare[1]}`;
      } else {
        this.displayDate2 = null;
      }
      this.$emit("change", obj);
      this.away();
    },
    date1Focused(selectedItem) {
      this.dateFromSelected = selectedItem;
      if (selectedItem) {
        setTimeout(() => {
          this.$refs[`picker1_to`].$refs[`picker1_to`].focus();
        }, 200);
      } else {
        setTimeout(() => {
          this.$refs[`picker1_from`].$refs[`picker1_from`].focus();
        }, 20);
      }
      this.showPicker1 = true;
    },
    date2Focused(selectedItem) {
      this.dateFromSelected = selectedItem;
      this.showPicker1 = false;
    },
    changeDate(dates) {
      this.date_filter = [
        moment(dates[0]).format("YYYY-MM-DD"),
        moment(dates[1]).format("YYYY-MM-DD")
      ];
      this.setDateRange(null);
    },
    changeDate2(dates) {
      this.date_filter_compare = [
        moment(dates[0]).format("YYYY-MM-DD"),
        moment(dates[1]).format("YYYY-MM-DD")
      ];
      this.setDateRange2(null);
    },
    dateFilter() {
      let oldDate = this.setDates(this.dateRange);
      let to = moment(this.date_filter[1]);
      let from = moment(this.date_filter[0]);

      if (
        oldDate.from != from.format("YYYY-MM-DD") ||
        oldDate.to != to.format("YYYY-MM-DD")
      ) {
        this.dateRange = null;
      }

      if (from.isValid()) {
        this.picker_date[0] = new Date(from.format("YYYY-MM-DD"));
        this.date_filter[0] = from.format("YYYY-MM-DD");
      }
      if (to.isValid()) {
        this.picker_date[1] = new Date(to.format("YYYY-MM-DD"));
        this.date_filter[1] = to.format("YYYY-MM-DD");
      }
      this.forceRerenderDatePicker();
    },
    dateFilter2() {
      let oldDate = this.setDatesCompare(this.dateRange2);
      let from = moment(this.date_filter_compare[0]);
      let to = moment(this.date_filter_compare[1]);

      if (
        oldDate.from != from.format("YYYY-MM-DD") ||
        oldDate.to != to.format("YYYY-MM-DD")
      ) {
        this.dateRange2 = null;
      }

      if (from.isValid()) {
        this.picker_date2[0] = new Date(from.format("YYYY-MM-DD"));
        this.date_filter_compare[0] = from.format("YYYY-MM-DD");
      }

      if (to.isValid()) {
        this.picker_date2[1] = new Date(to.format("YYYY-MM-DD"));
        this.date_filter_compare[1] = to.format("YYYY-MM-DD");
      }

      this.forceRerenderDatePicker();
    },
    forceRerenderDatePicker() {
      this.datepickerKey += 1;
    },
    setDates(range) {
      let from;
      let to;
      // this.dates.length = 0;

      if (range === "today") {
        from = moment().format("YYYY-MM-DD");
        to = moment().format("YYYY-MM-DD");
      } else if (range === "yesterday") {
        from = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        to = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      } else if (range === "last_seven_days") {
        from = moment()
          .subtract(6, "days")
          .format("YYYY-MM-DD");
        to = moment().format("YYYY-MM-DD");
      } else if (range === "last_thirty_days") {
        from = moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD");

        to = moment().format("YYYY-MM-DD");
      } else if (range === "current_month") {
        from = moment()
          .startOf("month")
          .format("YYYY-MM-DD");

        to = moment()
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (range === "last_month") {
        from = moment()
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");

        to = moment()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
      }
      return { from, to };
    },
    setDatesCompare(range) {
      let from, to;
      if (this.date_filter_compare) {
        from = moment(this.date_filter_compare[0]);
        to = moment(this.date_filter_compare[1]);
      } else {
        from = moment();
        to = moment();
      }

      if (range == "last_month") {
        from = moment()
          .subtract(2, "months")
          .format("YYYY-MM-DD");
        to = moment()
          .subtract(1, "months")
          .format("YYYY-MM-DD");
      } else if (range == "last_year") {
        from = moment()
          .subtract(2, "years")
          .format("YYYY-MM-DD");
        to = moment()
          .subtract(1, "years")
          .format("YYYY-MM-DD");
      } else {
        from = from.format("YYYY-MM-DD");
        to = to.format("YYYY-MM-DD");
      }

      return { from, to };
    }
  }
};
</script>
